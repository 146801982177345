<template>
<div class="rounded-2xl shadow hover:shadow-lg transition-all overflow-hidden">
  <slot></slot>
</div>
</template>

<script>
export default {
  name: "CardContainer"
}
</script>

<style scoped>

</style>
